












































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { useAsset, useBlob, useCoa } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DATE_FORMAT_yyyy_MM_DD } from "@/models/constants/date.constant";
import { RequestParamDownloadAssetDepreciationProjection } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataCoa } from "@/models/interface/settings.interface";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

type ParamsHeader = {
  company: string;
  startYear: string;
  endYear: string;
  book: string;
  assetType: string;
  coa: string;
};

const TYPE_ASSET = ["SGU", "FIXED_ASSET"] as const;
type TypeAssetVal = (typeof TYPE_ASSET)[number];

@Component({
  components: {
    DatePicker,
  },
})
export default class AssetDepreciationProjection extends Mixins(
  MNotificationVue
) {
  @Ref("Form") form!: FormModel;

  formModel = {
    startPeriod: "",
    endPeriod: "",
    bookId: "",
    typeAsset: null as TypeAssetVal | null,
    coaAccount: [] as Array<string>,
  };

  optBooks: Array<Option> = [];
  optAssetCategoryType: Array<Option> = [
    {
      key: 0,
      label: this.$t("lbl_sgu").toString(),
      value: "SGU",
    },
    {
      key: 1,
      label: this.$t("lbl_fixed_asset").toString(),
      value: "FIXED_ASSET", // intended
    },
  ];
  optCoaAccounts: Array<Option<DataCoa>> = [];

  loading = {
    book: false,
    download: false,
    coa: false,
  };

  formRules = {
    startPeriod: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    endPeriod: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    bookId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    typeAsset: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    coaAccount: [{ required: false }],
  };

  created(): void {
    this.fetchBooks();
    this.fetchCoa(new RequestQueryParams("isParent~false"));
  }

  get isFiscal(): boolean {
    const book = this.optBooks.find(
      item => item.value === this.formModel.bookId
    );
    return book?.label.toLowerCase().includes("fiscal") || false;
  }

  onSearchCoa(search = ""): void {
    const params = new RequestQueryParams();
    const q = ["isParent~false"];
    const builder = new SearchBuilder();
    if (search) {
      const findBy = builder
        .push(["code", search], { like: "both" })
        .or()
        .push(["description", search], { like: "both" })
        .build();
      q.unshift(findBy);
    }
    params.search = q.join(builder.AND);
    debounce(() => {
      this.fetchCoa(params);
    });
  }

  fetchCoa(params: RequestQueryParamsModel): void {
    const { findAll, mapToOption } = useCoa();
    params.sorts = "code:asc";
    this.loading.coa = true;
    findAll(params)
      .then(response => {
        this.optCoaAccounts = mapToOption(response.data);
      })
      .finally(() => {
        this.loading.coa = false;
      });
  }

  fetchBooks(): void {
    const params = new RequestQueryParams();
    const { findAllAssetBook, mapAssetBookToOption } = useAsset();
    this.loading.book = true;
    findAllAssetBook(params)
      .then(response => {
        this.optBooks = mapAssetBookToOption(response.data);
      })
      .finally(() => {
        this.loading.book = false;
      });
  }

  validateForm(): void {
    this.form.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifError("lbl_validation_required_error");
        return;
      }

      this.handleSubmit();
    });
  }

  async buildCoaParamHeader(accounts: string[]): Promise<string> {
    const builder = new SearchBuilder();
    const { findAll, mapToOption } = useCoa();
    const q: Array<string> = [];
    accounts.forEach(item => {
      q.push(builder.push(["secureId", item]).build());
      builder.destroy();
    });

    if (!q.length) {
      return "";
    }

    const response = await findAll({ search: q.join(builder.OR) });

    // transform to option for simplicity
    const transformed = mapToOption(response.data);

    return transformed.map<string>(item => item.label).join(";");
  }

  async buildParamsHeader(): Promise<string> {
    const { startPeriod, endPeriod, bookId, typeAsset, coaAccount } =
      this.formModel;

    const bookName = this.optBooks.find(item => item.value === bookId);

    const coaName = await this.buildCoaParamHeader(coaAccount);

    const obj: ParamsHeader = {
      company:
        this.$store.state.authStore.authData.companyName ||
        "PT. SATRIA PIRANTI PERKASA",
      startYear: moment(startPeriod).format(DATE_FORMAT_yyyy_MM_DD),
      endYear: moment(endPeriod).format(DATE_FORMAT_yyyy_MM_DD),
      book: bookName?.label || "",
      assetType: typeAsset || "",
      coa: coaName,
    };

    const params: Array<string> = [
      obj.company,
      obj.startYear,
      obj.endYear,
      obj.book,
      obj.assetType,
      obj.coa,
    ];

    return params.join(",");
  }

  async buildParams(): Promise<RequestParamDownloadAssetDepreciationProjection> {
    const { startPeriod, endPeriod, bookId, typeAsset, coaAccount } =
      this.formModel;
    const paramsHeader = await this.buildParamsHeader();
    const params: RequestParamDownloadAssetDepreciationProjection = {
      bookId,
      coaAccount: coaAccount.join(","),
      startPeriod: moment(startPeriod).format(DATE_FORMAT_yyyy_MM_DD), // format yyyy-MM-dd ex yyyy-01-01
      endPeriod: moment(endPeriod).format(DATE_FORMAT_yyyy_MM_DD), // format yyyy-MM-dd ex yyyy-MM-01
      params: paramsHeader,
      search: "",
      sorts: "transactionDate:asc",
    };

    if (typeAsset === "SGU") {
      params.typeAsset = typeAsset;
    }

    return params;
  }

  async handleSubmit(): Promise<void> {
    try {
      const { downloadAssetDepreciationProjection, downloadCommercialReports } =
        useAsset();
      this.loading.download = true;
      const { toDownload } = useBlob();
      const params = await this.buildParams();

      if (this.formModel.typeAsset?.toLowerCase() === "sgu") {
        const blob = await downloadAssetDepreciationProjection(params);
        toDownload(blob, "asset_depreciation_projection_sgu_report.xlsx");
      } else {
        const blob = await downloadCommercialReports(params);
        toDownload(blob, "asset_depreciation_projection_non-sgu_report.xlsx");
      }
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  resetForm(): void {
    this.form.resetFields();
  }

  onChangeBook(): void {
    this.formModel.typeAsset = null;
  }
}
